module Main exposing
    ( Model
    , Msg
    , init
    , main
    , subscriptions
    , update
    , view
    )

import Browser
import Dict exposing (Dict)
import Game exposing (update)
import Goal exposing (Goal)
import Html exposing (Html)
import Html.Attributes
import Html.Events
import Http
import Parser
import Parser.Custom as Parser
import Ports
import Random
import RemoteData exposing (WebData)
import Tad.Html as Html
import Tutorial


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        }


type alias Model =
    { stage : Stage
    , levels : WebData (List Goal)
    , outcomes : Outcomes
    }


type Stage
    = Menu
    | Playing Game.Model
    | Learning (List Goal) Game.Model


type alias Outcomes =
    Dict String Game.Outcome



-- INIT


type alias Flags =
    { levels_url : String
    , outcomes : List ( String, Bool )
    }


init : Flags -> ( Model, Cmd Msg )
init flags =
    ( { stage = Menu
      , levels = RemoteData.Loading
      , outcomes =
            flags.outcomes
                |> Dict.fromList
                |> Dict.map
                    (\_ won ->
                        if won then
                            Game.Won

                        else
                            Game.Lost
                    )
      }
    , fetchLevels GotLevelsResponse flags.levels_url
    )


fetchLevels : (Result Http.Error (List Goal) -> msg) -> String -> Cmd msg
fetchLevels tag url =
    let
        expectLevels : Http.Expect msg
        expectLevels =
            Http.expectString toMsg

        toMsg : Result Http.Error String -> msg
        toMsg result =
            result
                |> Result.andThen toHttpResult
                |> tag

        toHttpResult : String -> Result Http.Error (List Goal)
        toHttpResult text =
            text
                |> parseLevels
                |> Result.mapError Parser.deadEndsToString
                |> Result.mapError Http.BadBody
    in
    Http.get
        { url = url
        , expect = expectLevels
        }


view : Model -> Html Msg
view model =
    case model.stage of
        Menu ->
            viewMenu

        Playing game ->
            viewPlaying game model.outcomes

        Learning goals game ->
            viewLearning goals game


viewLearning : List Goal -> Game.Model -> Html Msg
viewLearning remaining game =
    [ game |> Game.view |> Html.map GotGameMsg
    , viewLearningFooter remaining
    ]
        |> Html.div
            [ Html.Attributes.style "height" "100%"
            , Html.Attributes.style "width" "100%"
            , Html.Attributes.style "display" "flex"
            , Html.Attributes.style "flex-direction" "column"
            ]


viewLearningFooter : List Goal -> Html Msg
viewLearningFooter remaining =
    Html.div
        [ Html.Attributes.style "display" "flex"
        , Html.Attributes.style "justify-content" "space-around"
        , Html.Attributes.style "width" "100%"
        , Html.Attributes.style "padding" "1em 0"
        , Html.Attributes.style "background" "hsl(0, 0%, 86%)"
        , Html.Attributes.style "box-shadow" "0px 0px 6px 2px hsla(0, 0%, 0%, 20%)"
        , Html.Attributes.style "z-index" "1"
        ]
        [ [ remaining |> List.length |> String.fromInt
          , " lessons remaining."
          ]
            |> List.map Html.text
            |> Html.p []
        ]


viewPlaying : Game.Model -> Outcomes -> Html Msg
viewPlaying game outcomes =
    [ game |> Game.view |> Html.map GotGameMsg
    , viewOutcomesFooter outcomes
    ]
        |> Html.div
            [ Html.Attributes.style "height" "100%"
            , Html.Attributes.style "width" "100%"
            , Html.Attributes.style "display" "flex"
            , Html.Attributes.style "flex-direction" "column"
            ]


viewOutcomesFooter : Outcomes -> Html msg
viewOutcomesFooter outcomes =
    let
        { killed, saved } =
            score outcomes
    in
    Html.div
        [ Html.Attributes.style "display" "flex"
        , Html.Attributes.style "justify-content" "space-around"
        , Html.Attributes.style "width" "100%"
        , Html.Attributes.style "padding" "1em 0"
        , Html.Attributes.style "background" "hsl(0, 0%, 86%)"
        , Html.Attributes.style "box-shadow" "0px 0px 6px 2px hsla(0, 0%, 0%, 20%)"
        , Html.Attributes.style "z-index" "1"
        ]
        [ [ "🐍 "
          , saved |> String.fromInt
          ]
            |> List.map Html.text
            |> Html.div
                []
        , [ "☠️ "
          , killed |> String.fromInt
          ]
            |> List.map Html.text
            |> Html.div
                []
        ]


viewMenu : Html Msg
viewMenu =
    Html.div
        [ Html.Attributes.style "text-align" "center"
        , Html.Attributes.style "max-width" "640px"
        , Html.Attributes.style "padding" "2rem"
        , Html.Attributes.style "line-height" "150%"
        ]
        [ Html.div
            [ Html.Attributes.style "display" "flex"
            , Html.Attributes.style "flex-direction" "column"
            , Html.Attributes.style "justify-content" "center"
            , Html.Attributes.style "align-items" "center"
            , Html.Attributes.style "gap" "1rem"
            , Html.Attributes.style "width" "20rem"
            , Html.Attributes.style "margin" "auto"
            ]
            [ Html.h1 [] [ Html.text "Word Snake" ]
            , Html.img
                [ Html.Attributes.src "/icon.svg"
                , Html.Attributes.style "width" "8rem"
                , Html.Attributes.style "display" "block"
                ]
                []
            , Html.button
                [ Html.Events.onClick StartButtonClicked
                , Html.Attributes.autofocus True
                , Html.Attributes.style "width" "100%"
                , Html.Attributes.style "font-size" "1.2rem"
                , Html.Attributes.style "font-weight" "bold"
                ]
                [ Html.text "Play" ]
            , Html.button
                [ Html.Events.onClick TutorialButtonClicked
                , Html.Attributes.autofocus True
                , Html.Attributes.style "width" "100%"
                , Html.Attributes.style "font-size" "1.2rem"
                , Html.Attributes.style "font-weight" "bold"
                ]
                [ Html.text "Learn" ]
            ]
        , Html.footer [ Html.Attributes.style "font-size" "0.8rem" ]
            [ Html.p []
                [ Html.text "Made by "
                , Html.a
                    [ Html.Attributes.href "https://tad-lispy.com/" ]
                    [ Html.text "Tad Lispy" ]
                , Html.text ".        "
                ]
            , Html.p []
                [ Html.text "This game is a "
                , Html.strong []
                    [ Html.text "free software" ]
                , Html.text ". You may copy, modify, redistribute it etc. under the terms of "
                , Html.a
                    [ Html.Attributes.href "https://www.gnu.org/licenses/gpl-3.0.html"
                    , Html.Attributes.target "_blank"
                    ]
                    [ Html.text "the GNU General Public License v. 3.0" ]
                , Html.text " or later. "
                , Html.a [ Html.Attributes.href "https://gitlab.com/hornbook/word-snake/" ]
                    [ Html.text "See the source code" ]
                , Html.text ". I respect your "
                , Html.a
                    [ Html.Attributes.href "privacy.html"
                    , Html.Attributes.target "_blank"
                    ]
                    [ Html.text "privacy" ]
                , Html.text ". Good luck, have fun!"
                ]
            ]
        ]



-- UPDATE


type Msg
    = GotLevelsResponse (Result Http.Error (List Goal))
    | StartButtonClicked
    | TutorialButtonClicked
    | GotGameMsg Game.Msg
    | GotRandomGameFlags (List Goal) Random.Seed


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        GotLevelsResponse response ->
            ( { model
                | levels =
                    response
                        |> RemoteData.fromResult
                        |> RemoteData.map (rescheduleLevels model.outcomes)
              }
            , Cmd.none
            )

        StartButtonClicked ->
            ( model
            , Cmd.batch
                [ Ports.fullscreen ()
                , model.levels
                    |> RemoteData.map (generateRandomGameFlags GotRandomGameFlags)
                    |> RemoteData.withDefault Cmd.none
                ]
            )

        TutorialButtonClicked ->
            ( { model
                | stage =
                    Game.init
                        { goal = Tutorial.intro

                        -- TODO: Do we need randomness in tutorial?
                        , randomness = Random.initialSeed 0
                        }
                        |> Learning Tutorial.goals
              }
            , Cmd.batch
                [ Ports.fullscreen ()
                ]
            )

        GotGameMsg Game.ContinueButtonClicked ->
            -- TODO: Find a more elegant way to signal the intention of
            -- progressing the game, that doesn't leak internal Msg variants
            case model.stage of
                Menu ->
                    -- TODO: Can't happen. Report an error?
                    ( model
                    , Cmd.none
                    )

                Playing game ->
                    case RemoteData.toMaybe model.levels of
                        Nothing ->
                            ( model
                            , Cmd.none
                            )

                        Just levels ->
                            let
                                outcome =
                                    Game.outcome game

                                trackGoal =
                                    case outcome of
                                        Game.Won ->
                                            -- TODO: Extract Fathom analytics to own module. Have a union type for goal ids.
                                            Ports.goalTracking ( "MNUMD96E", 0 )

                                        Game.Lost ->
                                            Ports.goalTracking ( "QHPNWQCC", 0 )

                                        Game.InProgress ->
                                            -- TODO: Report an error
                                            Cmd.none

                                rescheduledLevels =
                                    scheduleLevel 5 outcome levels
                            in
                            ( model
                            , [ generateRandomGameFlags GotRandomGameFlags rescheduledLevels
                              , trackGoal
                              , Ports.fullscreen ()
                              ]
                                |> Cmd.batch
                            )

                Learning goals game ->
                    case Game.outcome game of
                        Game.Won ->
                            case goals of
                                [] ->
                                    -- No more tutorials, time to play the game!
                                    case RemoteData.toMaybe model.levels of
                                        Nothing ->
                                            -- TODO: This should not happen. Report an error?
                                            ( { model | stage = Menu }
                                            , Cmd.none
                                            )

                                        Just levels ->
                                            ( model
                                            , [ generateRandomGameFlags GotRandomGameFlags levels
                                              , Ports.goalTracking ( "APLYXOZD", 1 )
                                              , Ports.fullscreen ()
                                              ]
                                                |> Cmd.batch
                                            )

                                nextGoal :: futureGoals ->
                                    ( { model
                                        | stage =
                                            game
                                                |> Game.continue nextGoal
                                                |> Learning futureGoals
                                      }
                                    , Cmd.none
                                    )

                        Game.Lost ->
                            ( { model
                                | stage =
                                    game
                                        |> Game.continue game.goal
                                        |> Learning goals
                              }
                            , Cmd.none
                            )

                        Game.InProgress ->
                            -- TODO: This should not happen. Report an error?
                            ( model
                            , Cmd.none
                            )

        GotGameMsg gameMsg ->
            case model.stage of
                Menu ->
                    -- TODO: Can't happen. Report an error?
                    ( model, Cmd.none )

                Playing game ->
                    let
                        ( updatedGame, gameCmd ) =
                            Game.update gameMsg game

                        outcome =
                            Game.outcome game
                    in
                    ( { model
                        | stage = Playing updatedGame
                        , outcomes =
                            Dict.insert
                                (Goal.serialize game.goal)
                                outcome
                                model.outcomes
                      }
                    , [ Cmd.map GotGameMsg gameCmd
                      , Ports.storeOutcome game.goal outcome
                      ]
                        |> Cmd.batch
                    )

                Learning goals game ->
                    let
                        ( updatedGame, gameCmd ) =
                            Game.update gameMsg game
                    in
                    ( { model
                        | stage = Learning goals updatedGame
                      }
                    , [ Cmd.map GotGameMsg gameCmd
                      ]
                        |> Cmd.batch
                    )

        GotRandomGameFlags levels randomness ->
            case levels of
                [] ->
                    -- TODO: This should never happen. Report an error.
                    ( model
                    , Cmd.none
                    )

                level :: _ ->
                    case model.stage of
                        Menu ->
                            ( { model
                                | levels = RemoteData.Success levels
                                , stage =
                                    { goal = level
                                    , randomness = randomness
                                    }
                                        |> Game.init
                                        |> Playing
                              }
                            , Cmd.none
                            )

                        Playing game ->
                            ( { model
                                | levels = RemoteData.Success levels
                                , stage =
                                    game
                                        |> Game.continue level
                                        |> Playing
                              }
                            , Cmd.none
                            )

                        Learning _ game ->
                            ( { model
                                | levels = RemoteData.Success levels
                                , stage =
                                    game
                                        |> Game.continue level
                                        |> Playing
                              }
                            , Cmd.none
                            )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    case model.stage of
        Menu ->
            Sub.none

        Playing game ->
            game
                |> Game.subscriptions
                |> Sub.map GotGameMsg

        Learning _ game ->
            game
                |> Game.subscriptions
                |> Sub.map GotGameMsg



-- HELPERS


type alias Score =
    { saved : Int
    , killed : Int
    , pending : Int
    }


score : Outcomes -> Score
score outcomes =
    let
        iterator : String -> Game.Outcome -> Score -> Score
        iterator _ outcome memo =
            case outcome of
                Game.Won ->
                    { memo
                        | saved = memo.saved + 1
                        , pending = memo.pending - 1
                    }

                Game.Lost ->
                    { memo
                        | killed = memo.killed + 1
                        , pending = memo.pending - 1
                    }

                Game.InProgress ->
                    memo
    in
    Dict.foldl
        iterator
        (Score 0 0 (Dict.size outcomes))
        outcomes


scheduleLevel : Int -> Game.Outcome -> List Goal -> List Goal
scheduleLevel push outcome levels =
    case levels of
        [] ->
            levels

        head :: rest ->
            if outcome == Game.Won then
                -- Move level to the end of the queue
                rest
                    |> List.reverse
                    |> (::) head
                    |> List.reverse

            else
                -- Move level 5 position back in the queue
                List.concat
                    [ List.take push rest
                    , [ head ]
                    , List.drop push rest
                    ]


rescheduleLevels : Outcomes -> List Goal -> List Goal
rescheduleLevels outcomes levels =
    levels
        |> List.indexedMap
            (\index level ->
                case
                    outcomes
                        |> Dict.get (Goal.serialize level)
                        |> Maybe.withDefault Game.InProgress
                of
                    Game.Won ->
                        ( index + List.length levels, level )

                    Game.Lost ->
                        ( index + 5, level )

                    Game.InProgress ->
                        ( index, level )
            )
        |> List.sortBy Tuple.first
        |> List.map Tuple.second


generateRandomGameFlags : (List Goal -> Random.Seed -> msg) -> List Goal -> Cmd msg
generateRandomGameFlags tag levels =
    Random.map2 tag
        (shuffleFirst 3 levels)
        Random.independentSeed
        |> Random.generate identity


shuffleFirst :
    Int
    -> List a
    -> Random.Generator (List a)
shuffleFirst number list =
    list
        |> List.take number
        |> randomOrder
        |> Random.map
            (\shuffled ->
                list
                    |> List.drop number
                    |> List.append shuffled
            )


randomOrder : List a -> Random.Generator (List a)
randomOrder list =
    Random.list
        (List.length list)
        (Random.float 0 100)
        |> Random.map (List.map2 Tuple.pair list)
        |> Random.map (List.sortBy Tuple.second)
        |> Random.map (List.map Tuple.first)


parseLevels : String -> Result (List Parser.DeadEnd) (List Goal)
parseLevels =
    Parser.run Parser.levels
