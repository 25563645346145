import { Elm } from "./Main.elm"
import * as outcomes_db from "idb-keyval"

levels =
    location
    .hash
    .trim()
    .slice 1

levels_url =
    if levels isnt ""
        levels

    else if process.env.NODE_ENV is "development"
        "/levels/default.snake"
    else
        "https://word-snake.software.garden/levels/default.snake"

outcomes_db
.entries()
.then (outcomes) =>
    node = document.getElementById "app"

    flags = {
        levels_url
        outcomes
    }

    game = Elm.Main.init { node, flags }

    document.addEventListener "keydown", (event) =>
        if event.key in [ "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight" ]
            do event.preventDefault
        game.ports.keydown.send event

    game.ports.fullscreen.subscribe () =>
        document.body.requestFullscreen? {}

    game.ports.goalTracking.subscribe ([goal, value]) =>
        if process.env.NODE_ENV is "production"
            fathom.trackGoal goal, value
        else
            console.debug "Tracking goals disabled in development environment."

    game.ports.outcomes.subscribe ([level, won]) =>
        outcomes_db.set level, won
